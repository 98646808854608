<template>
  <div>
    <div class="box jz">
      <div class="banner">
        <div v-if="bannerList.length > 0">
          <img :src="bannerList[0].bannerImg" alt="" @click="topage(bannerList[0].h5Url)">
        </div>
        <div v-else>
          <!-- <img src="~assets/banner.png" alt=""> -->
        </div>
      </div>
      <div class="mbox">
        <el-row type="flex" justify="center" :gutter="48">
          <el-col :span="5"></el-col>
          <el-col :span="3">
            <div v-for="(item, index) in msg" :key="index" :class="{ active: index == currentIndex }"
              @click="itemClick(index)" class="tab">
              <a style="margin:10px">{{ item }}</a>
            </div>
          </el-col>
          <el-col :span="16">
            <!-- <div class="mbox">
          <div class="container"> -->
            <!-- <span class="tknow" :class="{active : know}" @click="t1()">了解瑞尔安心</span>
              <span class="connect" :class="{active : connect}" @click="t2()">联系我们</span>
              <span class="service" :class="{active : service}" @click="t3()">服务条款</span> -->
            <div class="know" v-if="currentIndex == 0">
              <div class="k1">
                北京瑞尔视景科技有限公司（北京科创板挂牌企业代码：100136）是国家高新技术企业、中关村高新技术企业。致力于精准医疗健康科普知识的创作传播，大健康科普知识及服务平台，及专业研制、生产医用诊断可穿戴医疗设备的科技企业。自创立至今，始终秉承“行远必自迩，追求无止境”理念，以“精准科普，服务大众”为目标一、向全民提供“科普视频+语音科普+图文咨询+文章+大数据分析”多位一体的精准健康科普知识，满足大众多样化、多场景应用的健康科普需求。致力于通过互联网技术推动医生共享、知识共享、服务共享、经济共享，放大医生价值和健康科普内容价值，用通俗易懂的方式以及直白语言，制作出大众听得懂、记得住、学得会、用得上的健康科普知识，切实提升公众健康素养，让更多人能够健康地享受美好生活，助力全民健康。我们的优势：
              </div>
              <div class="k2">1】团队优势：专业的拍摄设备及影棚、经验丰富的拍摄团队、训练有素的后期队伍。</div>
              <div class="k3">2】大数据优势：拥有大数据分析能力和丰富的大数据库，有针对性的为医生选取热点内容，医生聚焦热点生产视频、音频、文章或问答等内容。</div>
              <div class="k4">
                3】多渠道分发优势：多渠道推广，多方位宣传矩阵，医生医院影响力增强，用户更精确。真诚邀请更多的医疗机构、医务工作者加入健康科普事业，以匠心精神打造医疗共享经济，为全民健康享受美好生活而务实进取、不懈奋斗！二、总部设立于北京，在浙江杭州设立分公司，坚持走自主创新与先进科技相结合得研发之路，引进欧美先进的生物信号监测技术，与国内三甲医院、知名院校，与国外世界知名大学澳大利亚悉尼大学、新加坡国立大学，以及多家中国上市企业合作。经过多年努力，成功攻克技术难关，研发出多款医疗器械，实现了心脑血管病患者、高危人群得日常疾病筛查和健康管理，并配套了全程专业的健康管理服务，在国内医疗产品行业中具有很高的地位和影响。公司主要从事公司下设医学研发中心、软件开发中心、生产制造中心、营销中心、医学服务中心等，成为高科技医疗器械行业翘楚。公司将一如既往的励精图治，开拓创新，为医疗卫生事业，做出我们永远的承诺。
              </div>
            </div>
            <div class="form" v-if="currentIndex == 1">
              <!-- <el-form ref="form" :model="form"> -->
              <div class="work">
                <el-row>
                  <el-col style="margin-bottom:20px">
                    <div class="bgtitle">
                      <div class="title">商务合作</div>
                      <div class="bg"></div>
                    </div>
                  </el-col>
                  <el-col class="col-margin" style="margin-top: 50px">
                    <span class="coojs">王女士</span>
                  </el-col>
                  <el-col class="col-margin">
                    <span class="coojs">010-86398553</span>
                  </el-col>
                  <el-col class="col-margin">
                    <span class="coojs">business@irealcare.com</span>
                  </el-col>
                  <el-col class="col-margin">
                    <span class="coojs">工作日（周一至周五）：9:00--18:00</span>
                  </el-col>
                </el-row>
              </div>
              <div class="tform">
                <div class="bgtitle" style="margin-bottom:60px">
                  <div class="title">意见反馈</div>
                  <div class="bg"></div>
                </div>
                <el-form ref="form" :label-position="'top'" :model="form" :rules="rule" style="margin-top: 30px">
                  <el-row :gutter="24">
                    <el-col :span="12">
                      <el-form-item label="您的姓名" prop="userName">
                        <el-input v-model="form.userName" placeholder="请输入您的姓名"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="联系方式" prop="phoneOrEmail">
                        <el-input v-model="form.phoneOrEmail" placeholder="请输入您的联系方式"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-form-item label="反馈内容" prop="advance">
                      <!-- <el-input v-model="form.type"></el-input> -->
                      <el-input type="textarea" v-model="form.advance" :rows="4" placeholder="请输入反馈内容"></el-input>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-button type="primary" @click="onSubmit()" class="btn">提交</el-button>
                  </el-row>
                </el-form>
                <!-- <span class="suggest">意见反馈</span>
                          <div class="bg"></div>
                          <span class="yname">您的姓名 *</span>
                          <el-input v-model="form.name" class="pname"></el-input>
                          <span class="yphone">联系方式 *</span>
                          <el-input v-model="form.phone" class="pphone"></el-input>
                          <span class="yfk">反馈内容 *</span>
                          <el-input type="textarea" v-model="form.desc" class="pdesc" :rows="4"></el-input>
                          <el-button type="primary" @click="onSubmit" class="btn">提交</el-button> -->
              </div>
              <!-- </el-form> -->
            </div>
            <div class="fw" v-if="currentIndex == 2">
              <span class="f1"><span class="title">一、 保护隐私权条款</span><br>
                瑞尔安心（http://www.irealcare.com/以下称“本网站”）隐私权保护声明系本网站保护用户个人隐私的承诺。鉴于网络的特性，本网站将无可避免地与您产生直接或间接的互动关系，故特此说明本网站对用户个人信息所采取的收集、使用和保护政策，请您务必仔细阅读：</span>
              <span class="f2">1、 非个人化信息<br>
                我们将通过您的IP地址来收集非个人化的信息，例如您的浏览器性质、操作系统种类、给您提供接入服务的ISP的域名等，以优化在您计算机屏幕上显示的页面。通过收集上述信息，我们亦进行客流量统计。这些无关个人身份的信息能帮助我们辩别瑞尔安心最受欢迎的地区并确定我们推广活动的有效性。此外，我们也可能将这些信息披露给我们的广告客户，使他们知道点击他们广告的人数。</span>
              <span class="f3">2、 个人资料<br>
                当您在瑞尔安心进行用户注册登记、参加公共论坛等活动时，在您的同意及确认下，本网站将通过注册表格、订单等形式要求您提供一些个人资料。这些个人资料包括：<br>
                1） 个人识别资料：如姓名、性别、年龄、出生日期、电话、通信地址等。<br>
                2） 个人背景：职业、教育程度、收入状况、婚姻、家庭状况。<br>
                请了解，在未经您同意及确认之前，本网站不会将您为参加本网站之特定活动所提供的资料利用于其它目的。惟按下列第六章规定应政府及法律要求披露时不在此限。<br>
                瑞尔安心收集这类关于个人身份的信息主要是为了其注册成员能够更容易和更满意地使用瑞尔安心的网页。我们的目标是向所有的互联网用户提供专业、有益、有趣的上网经历。而这些个人信息有助于我们实现这一目标。</span>
              <span class="f4">3、 信息安全<br>
                1） 本网站将对您所提供的资料进行严格的管理及保护，本网站将使用相应的技术，防止您的个人资料丢失、被盗用或遭篡改。<br>
                2） 本网站在必要时委托专业技术人员代为对该类资料进行电脑处理，以符合专业分工之要求。如本网站将电脑处理之通知送达予您，而您未在通知规定的时间内主动明示反对，本网站将推定您已同意。</span>
              <span class="f5">4、 用户权利<br>
                1） 您对于自己的个人资料享有以下权利：<br>
                （1）随时查询及请求阅览；<br>
                （2）随时请求补充或更正；<br>
                （3）随时请求删除；<br>
                （4）请求停止电脑处理及利用。<br>
                2）
                瑞尔安心为其用户提供的网上游戏项目，为了参加抽签，我们通常要求申请者在联系方法(通讯地址和电话)之外，还要根据其个人兴趣填写表格。此类信息被瑞尔安心用来累积其用户的数据。如果用户不想提供所要求的信息和参加比赛，这完全是用户的决定。<br>
                3） 用户是唯一对自己的口令及其他帐户信息负有保密责任的人。当你在线时，请小心和负责。</span>
              <span class="f6">5、 限制利用原则<br>
                本网站惟在符合下列条件之一，方对收集之个人资料进行必要范围以外之利用：<br>
                1） 已取得您的书面同意；<br>
                2） 为免除您在生命、身体或财产方面之急迫危险；<br>
                3） 为防止他人权益之重大危害；<br>
                4） 为增进公共利益，且无害于您的重大利益。</span>
              <span class="f7">6、 个人资料之披露<br>
                当政府机关依照法定程序要求本网站披露个人资料时，本网站将根据执法单位之要求或为公共安全之目的提供个人资料。在此情况下之任何披露，本网站均得免责。</span>
              <span class="f8">7、 公共论坛<br>
                瑞尔安心为您提供问专家等服务。在这些区域内，您公布的任何信息都会成为公开的信息。因此，我们提醒并请您慎重考虑是否有必要在这些区域公开您的个人信息。</span>
              <span class="f9">8、 免责<br>
                除上述（六）之规定属免责外，下列情况时本网站亦毋需承担任何责任：<br>
                1） 由于您将用户密码告知他人或与他人共享注册帐户，由此导致的任何个人资料泄露。<br>
                2） 任何由于计算机问题、黑客政击、计算机病毒侵入或发作、因政府管制而造成的暂时性关闭等影响网络正常经营之不可抗力而造成的个人资料泄露、丢失、被盗用或被窜改等。<br>
                3）
                瑞尔安心的网页上有与其他网站网页的链接，包括旗帜广告和网页上的其他广告。瑞尔安心对其他任何网站的内容、隐私政策或运营，或经营这些网站的公司的行为概不负责。在向这些与瑞尔安心链接的网站提供个人信息之前，请查阅它们的隐私政策。<br>
                4） 由于与本网站链接的其它网站所造成之个人资料泄露及由此而导致的任何法律争议和后果。本网站之保护隐私声明的修改及更新权均属于瑞尔安心。</span>
              <span class="f10"><span class="title">二、 免责条款</span><br>
                除瑞尔安心注明之服务条款外，其它因使用瑞尔安心而引致之任何意外、疏忽、合约毁坏、诽谤、版权或知识产权侵犯及其所造成的各种损失（包括因下载而感染电脑病毒），瑞尔安心概不负责，亦不承担任何法律责任。<br>
                任何透过瑞尔安心网页而链接及得到之资讯、产品及服务，瑞尔安心概不负责，亦不负任何法律责任。<br>
                瑞尔安心内所有内容并不反映任何瑞尔安心之意见及观点。<br>
                瑞尔安心认为，一切网民在进入瑞尔安心主页及各层页面时已经仔细看过本条款并完全同意。</span>
              <span class="f11"><span class="title">三、 版权条款</span><br>
                为了保护知识产权，保障著作人和版权所有者的合法权益，保护瑞尔安心的合法权益，瑞尔安心声明：<br>
                1）
                瑞尔安心（http://www.irealcare.com/）及其注册用户及本网站所有网页内的资料提供者拥有此网页内所有资料的版权。未经瑞尔安心的明确书面许可，任何人不得复制、转载、摘编、使用瑞尔安心所有网页内容，不得在非瑞尔安心所属的服务器上做镜像，不得对瑞尔安心的全部或部分页面、内容做商业链接。<br>
                2） 任何团体与个人，拟在Internet上使用瑞尔安心的图、文、视/音频及其它信息资源，须事先与瑞尔安心联络并取得明确的书面许可。<br>
                3） 瑞尔安心不保证为向用户提供便利而设置的外部链接的准确性和完整性的工作模式。</span>
              <!-- </div>
          </div> -->
            </div>
            <div class="know" v-if="currentIndex == 3">
              <div class="k5">
                瑞尔安心安全用药平台（下称“平台”）致力于为患者提供相对及时全面的疾病、药品、指南等信息以辅助临床决策。
              </div>
              <div class="k6">平台涉及的医学信息内容广泛，但这些内容不包含所有适当的诊疗护理方法，也未排除合理运用可获得相同治疗结果的诊疗、护理方法，亦不视为平台提供了任何医疗建议、诊断或治疗方法。关于任何特定诊疗程序的适当性，必须由医生根据患者个体表现出的所有情况做出最终判断。平台对于任何用户因使用或参考本平台内容而做出的任何判断或决定以及该等判断或决定直接或间接导致的任何后果不承担任何责任。</div>
              <div class="k7">平台提供的部分内容源自互联网的资料信息或一些非规范性文件，初衷和目的是用于学术交流与讨论、补充临床诊疗过程中的信息及注意事项，并不意味着平台对前述内容有任何主观的认可或推荐。如果您认为我们的转载侵犯了您的权益，请与我们联系（联系邮箱：business@irealcare.com），我们将在核实后5个工作日内予以删除处理。</div>
              <div class="k8">
                平台会尽可能及时的、全面的收集展示最新的疾病、药品、指南等信息，但医药科学知识不断更新，其涵盖的信息也不断变化，可能导致部分信息缺失或滞后，我们无法保障平台提供的全部信息为当前最新最及时信息，请您谅解，也欢迎您向平台反馈、纠正平台上滞后、错误的信息，提出您宝贵的意见。
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <el-row>
      <el-col>
        <bottom-bar class="bottom"></bottom-bar>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import BottomBar from 'components/content/bottombar/BottomBar'
import {
  addAdvance,
  getBanner
} from 'network/getData'

export default {
  name: 'MoreService',
  components: {
    BottomBar
  },
  data() {
    return {
      msg: ['了解瑞尔安心', '联系我们', '服务条款','免责声明'],
      bannerList: [],
      know: false,
      connect: true,
      service: false,
      // tsize: '960px',
      // size: '660px',
      // bsize: '600px',
      currentIndex: null,
      form: {
        userName: '',
        phoneOrEmail: '',
        advance: ''
      },
      rule: {
        userName: [
          { required: true, message: '姓名不能为空', trigger: 'blur' }
        ],
        phoneOrEmail: [
          { required: true, message: '联系方式不能为空', trigger: 'blur' }
        ],
        advance: [
          { required: true, message: '反馈内容不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    console.log(this.$route.query.current,'123');
    this.currentIndex = this.$route.query.current ? this.$route.query.current : 0
    console.log(this.currentIndex);
    // this.currentIndex = 1
    this.getBannerList('system')
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          addAdvance(this.form.userName, this.form.phoneOrEmail, this.form.advance).then(
            res => {
              if (res.data.code === 200) {
                this.$message({
                  message: '反馈提交成功',
                  type: 'success'
                })
              } else {
                this.$message.error('反馈提交失败')
              }
            }
          )
        }
      })
    },
    async getBannerList(type) {
      try {
        const result = await getBanner(type)
        this.bannerList = result.data.data
        // console.log('this.bannerList', this.bannerList)
      } catch (error) {
        // console.log(error)
      }
    },
    topage(e) {
      if (e !== 'empty') {
        window.open(e)
      }
    },
    itemClick(index) {
      this.currentIndex = index
      //   if (this.currentIndex === 1) {
      //     this.size = '880px'
      //     this.tsize = '1180px'
      //     this.bsize = '820px'
      //   } else if (this.currentIndex === 2) {
      //     this.size = '2140px'
      //     this.tsize = '2440px'
      //     this.bsize = '2080px'
      //   } else {
      //     this.size = '660px'
      //     this.tsize = '960px'
      //     this.bsize = '600px'
      //   }
    }
  }
}
</script>

<style scoped>
.box {
  position: relative;
  /* width: 1440px; */
  /* background: #FAFAFA; */
}

.banner {
  /* position: absolute; */
  width: 1440px;
  height: 200px;
  margin: 0 auto;
  /* left: 120px; */
  /* background: #E8E8E8; */
  /* background: url("~assets/banner.png") no-repeat; */
}

.banner .img {
  /* position: absolute; */
  width: 1440px;
  height: 366px;
  left: -39px;
  top: -5px;
  /* background-image: url("~assets/banner.png"); */
}

.mbox {
  background: #FFFFFF;
  padding: 50px 0;
  margin: 0 auto;
  width: 1440px;
}

.bottom {
  /* position: absolute; */
  /* width: 1440px; */
  /* height: 594px;
      left: 0px;
      top: 500px; */
  margin-top: 80px;
  background: #FFFFFF;
  /* flex: none;
      order: 1;
      flex-grow: 0; */
  /* margin: 30px 0px; */
}

.container {
  /* position: absolute; */
  width: 1440px;
  height: 880px;
  /* left: 120px; */
  /* margin-left:120px; */
  top: -100px;
  background: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
  /* margin: 30px 0px; */
}

.tab {
  /* position: absolute; */
  /* width: 116px;
      height: 24px;
      left: 86px;
      top: 50px; */
  /* float: right; */
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: #8C8C8C;
  margin-bottom: 50px;
}

.active {
  color: #262626;
  /* font-family: 'OPPOSans B'; */
  font-weight: bold;
}

.know {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  /* position: absolute; */
  width: 580px;
  height: 550px;
  left: 430px;
  top: 50px;
}

.k1 {
  position: static;
  width: 580px;
  height: 198px;
  left: 0px;
  top: 0px;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
  flex: none;
  order: 0;
  flex-grow: 0;
  /* margin: 22px 0px; */
  margin-bottom: 22px;
}

.k2 {
  position: static;
  width: 580px;
  height: 22px;
  left: 0px;
  top: 220px;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
  flex: none;
  order: 1;
  flex-grow: 0;
  /* margin: 22px 0px; */
  margin-bottom: 22px;
}

.k3 {
  position: static;
  width: 580px;
  height: 44px;
  left: 0px;
  top: 264px;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
  flex: none;
  order: 2;
  flex-grow: 0;
  /* margin: 22px 0px; */
  margin-bottom: 22px;
}

.k4 {
  position: static;
  width: 580px;
  height: 220px;
  left: 0px;
  top: 330px;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: flex-end;
  color: #595959;
  flex: none;
  order: 3;
  flex-grow: 0;
  /* margin: 22px 0px; */
  margin-bottom: 22px;
}
.k5 {
  position: static;
  width: 580px;
  height: 44px;
  left: 0px;
  top: 0px;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
  flex: none;
  order: 0;
  flex-grow: 0;
  /* margin: 22px 0px; */
  margin-bottom: 22px;
}
.k6 {
  position: static;
  width: 580px;
  height: 110px;
  left: 0px;
  top: 66px;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: flex-end;
  color: #595959;
  flex: none;
  order: 3;
  flex-grow: 0;
  /* margin: 22px 0px; */
  margin-bottom: 22px;
}
.k7 {
  position: static;
  width: 580px;
  height: 88px;
  left: 0px;
  top: 198px;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: flex-end;
  color: #595959;
  flex: none;
  order: 3;
  flex-grow: 0;
  /* margin: 22px 0px; */
  margin-bottom: 22px;
}
.k8 {
  position: static;
  width: 580px;
  height: 88px;
  left: 0px;
  top: 308px;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: flex-end;
  color: #595959;
  flex: none;
  order: 3;
  flex-grow: 0;
  /* margin: 22px 0px; */
  margin-bottom: 22px;
}

.form {
  /* position: absolute; */
  width: 80%;
  /* height: 780px; */
  padding: 30px 64px;
  background: #FAFAFA;
  /* box-shadow: 0px 20px 40px rgba(3, 0, 77, 0.06); */
  border-radius: 12px;
  margin-bottom: 50px;
}

.el-col .coojs {
  color: #595959;
  font-size: 16px;
}

.work .col-margin {
  margin-top: 30px;
}

.work {
  margin-bottom: 30px;
}

.hz {
  /* position: absolute; */
  width: 80px;
  height: 28px;
  left: 64px;
  top: 0px;
  font-size: 20px;
  line-height: 28px;
  color: #262626;
}

.name {
  /* position: absolute; */
  width: 48px;
  height: 24px;
  left: 94px;
  top: 58px;
  font-size: 16px;
  line-height: 24px;
  color: #595959;
}

.bgtitle {
  width: 80px;
  position: relative;
  font-size: 20px;
  line-height: 28px;
  z-index: 2;
  color: #262626;
}

.bgtitle .title {
  position: absolute;
  top: 14px;
}

.bgtitle .bg {
  position: relative;
  width: 80px;
  height: 14px;
  background-color: #4263EB;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  opacity: 0.2;
  z-index: 1;
  bottom: -30px;
}

.tform span {
  color: #262626;
  font-size: 16px;
  line-height: 24px;
}

.suggest {
  /* position: absolute; */
  width: 80px;
  height: 28px;
  left: 64px;
  top: 0px;
  font-weight: 900;
  font-size: 20px;
  line-height: 28px;
  color: #262626;
}

.yname {
  position: absolute;
  width: 98px;
  height: 24px;
  left: 64px;
  top: 58px;
}

.yfk {
  position: absolute;
  width: 98px;
  height: 24px;
  left: 64px;
  top: 180px;
}

.yphone {
  position: absolute;
  width: 98px;
  height: 24px;
  left: 495px;
  top: 59px;
}

.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 104px;
  /* position: absolute; */
  width: 248px;
  height: 60px;
  left: 64px;
  top: 396px;
}

.fw {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  /* position: absolute; */
  width: 580px;
  /* height: 1920px; */
  left: 430px;
  top: 50px;
}

.fw span {
  left: 0px;
  font-size: 14px;
  width: 580px;
  line-height: 24px;
  color: #8C8C8C;
  position: static;
  margin-bottom: 22px;
}

.fw .title {
  color: #262626;
  font-size: 16px;
}

a {
  cursor: pointer;
}
</style>